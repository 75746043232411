<template>
  <div class="info-body">
    <div class="info">
      <div class="left">
        <div class="left-top box">
          <img src="@/image/home/guanhuai.png" />
          <div class="box-content">
            <div class="content">
              <ul class="icon">
                <li>
                  <a href="#" @click="goDetail('/achievements')">点击进入</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="left-bottom box">
          <img src="@/image/home/yingxiao.png" />
          <div class="box-content">
            <div class="content">
              <ul class="icon">
                <li>
                  <a href="#" @click="goDetail('/internet')">点击进入</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="middle-one box">
          <img src="@/image/home/yibu.png" />
          <div class="box-content">
            <div class="content">
              <el-carousel
                :interval="5000"
                indicator-position="none"
                height="25vh"
              >
                <el-carousel-item
                  style="width: 100%; padding: 0 1.2vw"
                  v-for="(item, index) in swiper_list"
                  :key="index"
                >
                  <div
                    style="
                      width: 90%;
                      margin: 0 auto;
                      white-space: pre-wrap;
                      text-align: justify;
                    "
                  >
                    <span class="post">{{ item }} </span>
                  </div>
                </el-carousel-item>
              </el-carousel>

              <ul class="icon">
                <li>
                  <a href="#" @click="gotuiframe('https://nav.tourgansu.com/')"
                    >点击进入</a
                  >
                </li>
                <li><a href="#" @click="onShowVideo('1')">宣传视频</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="middle-two box">
          <img src="@/image/home/zhengce.png" />
          <div class="box-content">
            <div class="content">
              <ul class="icon">
                <li>
                  <a href="#" @click="goDetail('/zhengce')">点击进入</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-top box">
          <img src="@/image/home/chanpin.png" />
          <div class="box-content">
            <div class="content">
              <span class="post"
                >建成90个景区(其中83个4A景区，7个5A景区)，共2022路视频监控信号接入，实现了重点景区重点部位线上监控视频和立体管理。</span
              >
              <ul class="icon">
                <li>
                  <a href="#">点击进入</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right-middle box">
          <img src="@/image/home/dashuju.png" />
          <div class="box-content">
            <div class="content">
              <!--                            <h3 class="title">大数据中心</h3>-->
              <span class="post"
                >甘肃文旅大数据中心纵向贯通了市县旅游部门及景区、酒店、旅行社数据，横向对接了景区（场馆）分时预约、公安、交通、民航等数据，使旅游数据统计更加精准高效。</span
              >
              <ul class="icon">
                <li style="width: 50px">
                  <a
                    href="#"
                    @click="
                      gotuiframe('https://traveldata.yougansu.com/visualC/')
                    "
                    >点击进入</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right-bottom box">
          <img src="@/image/home/shiyanshi.png" />
          <div class="box-content">
            <div class="content">
              <!-- <span class="post"
                >完成116个景区（其中110个4A景区6个5A景区），共1484路视频监控信号接入，实现了重点景区重点部位试试监控（视频）和立体管理。</span
              > -->
              <ul class="icon">
                <li>
                  <a href="#" @click="goDetail('/shiyanshi')">点击进入</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="showVideo"
      width="79%"
      top="0"
      center
      @close="closePlay"
    >
      <div class="video-index">
        <video-player
          :video-url="videoPath"
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          :state="state"
        >
        </video-player>
        <!--                <video style="width: 100%;height: 100%" :state = "state" ref="videoPlayer" :src="videoPath" controls></video>-->
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { videoPlayer } from 'vue-video-player'
import "video.js/dist/video-js.css";
export default {
  name: "index",
  props: {
    msg: String,
  },
  components: {},
  data() {
    return {
      swiperOption: {
        loop: true,
        spaceBetween: 30,
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 2000,
        },
        // circular:true
      },
      swiper_list: [
        "2019年荣获第四届中国文旅产业巅峰大会突出贡献奖，腾讯全球数字生态大会数字文旅先锋奖。\n2020年被文旅部评为2020年度文化和旅游信息化发展典型案例。\n2021年被新华社评为2021公共文化服务提升典范项目（案例）。\n2022年入选2022公共文旅服务创优推荐案例。",
        "全国率先建成上线的“一部手机游甘肃”，以“金牌导游、贴心管家、全能导购、文化导师”为指导，实现了全省4A5A级旅游景区智能导游导览、语音讲解、VR全景、旅游投诉等功能，接入基础数据4万余条，使全省文旅行业进入信息化智慧化时代。自平台上线以来，累计服务游客已超过1500万次。",
        "2019年荣获第四届中国文旅产业巅峰大会突出贡献奖，腾讯全球数字生态大会数字文旅先锋奖。\n2020年被文旅部评为2020年度文化和旅游信息化发展典型案例。\n2021年被新华社评为2021公共文化服务提升典范项目（案例）。\n2022年入选2022公共文旅服务创优推荐案例。",
        "全国率先建成上线的“一部手机游甘肃”，以“金牌导游、贴心管家、全能导购、文化导师”为指导，实现了全省4A5A级旅游景区智能导游导览、语音讲解、VR全景、旅游投诉等功能，接入基础数据4万余条，使全省文旅行业进入信息化智慧化时代。自平台上线以来，累计服务游客已超过1500万次。",
      ],
      state: 0,
      videoPath: "",
      showVideo: false,
      video: "",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/shouye/%E5%AE%A3%E4%BC%A0%E7%89%871.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        // poster: "src/images/logo.png", //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  watch: {
    //更改视频源 videoUrl从弹出框组件传值
    videoUrl: function (val) {
      if (val !== "") {
        this.$refs.videoPlayer.player.src(val);
      }
    },
    //弹出框关闭后暂停 否则一直在播放 state从弹出框组件传值

    state: function (val) {
      if (val) {
        this.$refs.videoPlayer.player.pause();
      }
    },
  },
  mounted() {},
  methods: {
    gotuiframe: function (url) {
      this.$router.push("/iframe?url=" + url);
    },
    closePlay: function () {
      this.$refs.videoPlayer.player.pause();
    },
    onShowVideo: function (type) {
      switch (type) {
        //景区导览
        case "1":
          this.playerOptions.sources[0].src =
            "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/shouye/%E5%AE%A3%E4%BC%A0%E7%89%871.mp4";
          break;
        //全景甘肃
        case "2":
          this.playerOptions.sources[0].src =
            "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/shouye/%E5%AE%A3%E4%BC%A0%E7%89%872.mp4";
          break;
        case "3":
          this.playerOptions.sources[0].src =
            "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/shouye/%E6%B8%B8%E5%AE%A2%E7%89%88.mp4";
          break;
      }
      this.showVideo = !this.showVideo;
    },
    goDetail: function (route) {
      this.$router.push({ path: route });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../../style/box.css";

.info-body {
  height: 100vh;
  width: 100vw;
  background: url(../../image/home/beijingNew.png) no-repeat center;
  background-size: 100% 100%;
  padding: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info {
  display: flex;
  align-items: center;
  flex: 0.9;
  margin-top: 10vh;

  background: transparent;
}
.info div {
  border-radius: 14px;
}

.info img {
  border-radius: 14px;
  width: 100%;
  height: 100%;
}
.left {
  margin-right: 0.5vw;
  /* width: 33%; */
}
.middle {
  margin-right: 0.5vw;
  /* width: 33%; */
}
.middle-one {
}
.left-top,
.middle-one,
.right-top,
.right-middle {
  margin-bottom: 1vh;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.24);
}
.left-bottom,
.middle-two,
.right-bottom {
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5);
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video-js .vjs-big-play-button {
  top: 44%;
  left: 48%;
  border-radius: 50%;
  height: 3em;
  border: none;
  line-height: 2.8em;
  background-color: rgba(255, 255, 255, 0.3);
}
.post {
  font-size: 0.8vw !important;
}
</style>
